import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input, AfterViewInit, HostListener } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CalendarEventDetailDialogComponent } from '../calendar-event-detail-dialog/calendar-event-detail-dialog.component';
import CalendarService from 'src/app/services/calendar.service';
import LoadingService from 'src/app/services/loading.service';

export interface ICalendarEvent {
  body: string;
  categories: string[];
  createdDateTime: string;
  end: string;
  endWithTimeZone: string;
  iCalUId: string;
  id: string;
  importance: string;
  isAllDay: boolean;
  isHtml: boolean;
  isReminderOn: boolean;
  lastModifiedDateTime: string;
  location: string;
  numberOfOccurences: number | null;
  optionalAttendees: string;
  organizer: string;
  recurrence: string;
  recurrenceEnd: string;
  reminderMinutesBeforeStart: number;
  requiredAttendees: string;
  resourceAttendees: string;
  responseRequested: boolean;
  responseTime: string;
  responseType: string;
  selectedDaysOfWeek: number[] | null;
  sensitivity: string;
  seriesMasterId: string | null;
  showAs: string;
  start: string;
  startWithTimeZone: string;
  subject: string;
  timeZone: string;
  webLink: string;
  eventType?: CalendarEventType;
  startMultipleDays?: string; // for multiple days event only
  endMultipleDays?: string; // for multiple days event only
}

export enum CalendarEventType {
  ALL_DAY = 'all day',
  PARTIAL_SINGLE = 'partial day single',
  PARTIAL_MULTIPLE = 'partial day multiple'
};

export interface ICalendarDay {
  year: number,
  month: number,
  day: number,
  isCurrentMonth: boolean,
  dayType: 'past' | 'today' | 'future'
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  events: ICalendarEvent[] = [];
  daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  calendarDays: ICalendarDay[][] = [];
  currentMonth: Date = new Date(); // Actual current month
  selectedMonth: Date = new Date(); // The month currently being viewed
  hiddenEventCounts: { [key: number]: number } = {};
  isExpanded: { [key: number]: boolean } = {}; // Track expanded state
  tooltipVisible = false;
  tooltipPosition = { x: 0, y: 0 };
  hoveredEvent: any = null;
  isLoading = true; // Add loading state variable
  private subscriptions: Subscription[] = []; // To manage subscriptions
  public CalendarEventType = CalendarEventType;

  constructor(
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private calendarService: CalendarService // Inject CalendarService
  ) {}

  ngOnInit(): void {
    this.loadingService.start();
    this.fetchEvents();
    this.generateCalendar();
    this.getCalendarContainerWidth();
    this.fetchCalendarUsers();
    this.loadingService.stop();
  }

  fetchEvents(): void {
    this.isLoading = true; // Set loading to true before starting the fetch
  
    // Get the start and end dates for the entire calendar view
    const firstDayOfCalendar = this.getFirstDayOfCalendar();
    const lastDayOfCalendar = this.getLastDayOfCalendar();
  
    // Add buffer days before and after the calendar view
    const bufferDays = 1; // Adjust the buffer as needed
  
    // Create new Date objects to avoid mutating the originals
    const startDate = new Date(firstDayOfCalendar);
    startDate.setDate(startDate.getDate() - bufferDays);
  
    const endDate = new Date(lastDayOfCalendar);
    endDate.setDate(endDate.getDate() + bufferDays);
  
    // Format the dates for the API call
    const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en-US');
    const formattedEndDate = formatDate(endDate, 'yyyy-MM-dd', 'en-US');
  
    this.subscriptions.push(
      this.calendarService.getEvents(formattedStartDate, formattedEndDate)
        .pipe(
          catchError(() => {
            this.isLoading = false; // Set loading to false if there's an error
            return of([]); // Return an empty array in case of an error
          })
        )
        .subscribe(data => {
          // Initialize events list
          this.events = [];

          data['value'].forEach((event: ICalendarEvent) => {
            // Convert UTC times to local timezone
            const localStart = new Date(event.start);
            const localEnd = new Date(event.end);

            // Set all-day events start and end time to midnight
            if (event.isAllDay) {
              localStart.setHours(0, 0, 0, 0); 
              localEnd.setHours(0, 0, 0, 0);   
            }

            // Determine the event type
            let eventType: CalendarEventType;
            if (event.isAllDay) {
              eventType = CalendarEventType.ALL_DAY;
            } else {
              eventType = localStart.toDateString() === localEnd.toDateString()
                ? CalendarEventType.PARTIAL_SINGLE
                : CalendarEventType.PARTIAL_MULTIPLE;
            }

            // Create base event
            const baseEvent = {
              ...event,
              start: localStart.toISOString(),
              end: localEnd.toISOString(),
              eventType
            };

            // Create multi-day events (applies to both all-day and partial-day events)
            if (localEnd > localStart) {
              let current = new Date(localStart);
              
              while (current < localEnd) {
                const nextDay = new Date(current);
                nextDay.setDate(current.getDate() + 1);

                this.events.push({
                  ...baseEvent,
                  start: new Date(current).toISOString(),
                  end: new Date(nextDay).toISOString(),
                  startMultipleDays: baseEvent.start,
                  endMultipleDays: baseEvent.end
                });
                current = nextDay;
              }
            } else {
              // Add single-day events
              this.events.push(baseEvent);
            }
          });

          console.log('events:', this.events);
          this.isLoading = false; // Set loading to false once data is received
          this.generateCalendar(); // Re-generate calendar with the new data
        })
    );
  }
  
  
  // Get the first day of the calendar view
  getFirstDayOfCalendar(): Date {
    const year = this.selectedMonth.getFullYear();
    const month = this.selectedMonth.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const startDay = firstDayOfMonth.getDay();
    const prevMonthDays = new Date(year, month, 0).getDate();
    
    // Calculate the start date of the calendar view
    const startDate = new Date(year, month, 1 - startDay);
    return startDate;
  }
  
  // Get the last day of the calendar view
  getLastDayOfCalendar(): Date {
    const year = this.selectedMonth.getFullYear();
    const month = this.selectedMonth.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const endDay = lastDayOfMonth.getDay();
    
    // Calculate the end date of the calendar view
    const endDate = new Date(year, month + 1, 6 - endDay);
    return endDate;
  }
  

  generateCalendar(): void {
    const year = this.selectedMonth.getFullYear();
    const month = this.selectedMonth.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const startDay = firstDayOfMonth.getDay();
    const endDay = lastDayOfMonth.getDay();
    const weeks: ICalendarDay[][] = [];
    let week: ICalendarDay[] = [];
    const prevMonthDays = new Date(year, month, 0).getDate();
    const today = new Date();

    // Helper function to determine dayType
    const getDayType = (date: Date): 'past' | 'today' | 'future' => {
      if (date.toDateString() === today.toDateString()) {
        return 'today';
      } else if (date < today) {
        return 'past';
      } else {
        return 'future';
      }
    };
    // Days from the previous month
    for (let day = prevMonthDays - startDay + 1; day <= prevMonthDays; day++) {
      const date = new Date(year, month - 1, day);
      const calendarDay: ICalendarDay = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: day,
        isCurrentMonth: false,
        dayType: getDayType(date) 
      };
      week.push(calendarDay);
    }
    // Days from the current month
    for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
      const date = new Date(year, month, day);
      const calendarDay: ICalendarDay = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: day,
        isCurrentMonth: true,
        dayType: getDayType(date) 
      };
      week.push(calendarDay);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    }
    // Days of the next month
    for (let day = 1; day <= 6 - endDay; day++) {
      const date = new Date(year, month + 1, day);
      const calendarDay: ICalendarDay = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: day,
        isCurrentMonth: false,
        dayType: getDayType(date) 
      };
      week.push(calendarDay);
    }

    if (week.length) {
      weeks.push(week);
    }

    this.calendarDays = weeks;
    console.log('calendarDays', this.calendarDays);
  }

  todayMonth(): void {
    this.selectedMonth = new Date();
    this.fetchEvents(); // Fetch events for the selected month
    this.isExpanded = {};
  }

  prevMonth(): void {
    // Create a new date object to avoid mutating the existing one
    this.selectedMonth = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth() - 1, 1);
    this.fetchEvents(); // Fetch events for the selected month
    this.isExpanded = {};
  }

  nextMonth(): void {
    // Create a new date object to avoid mutating the existing one
    this.selectedMonth = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth() + 1, 1);
    this.fetchEvents(); // Fetch events for the selected month
    this.isExpanded = {};
  }

  viewMonth(): void {
    // Implement viewMonth logic if needed
    this.generateCalendar();
  }

  viewWeek(): void {
    // Implement viewWeek logic if needed
    // You might need additional logic for weekly view
  }

  viewDay(): void {
    // Implement viewDay logic if needed
    // You might need additional logic for daily view
  }

  getEventsForDay(day: any): ICalendarEvent[] {
    const date = formatDate(new Date(day.year, day.month, day.day), 'yyyy-MM-dd', 'en-US');
    
    // Filter events matching the specified date
    const eventList = Array.isArray(this.events)
      ? this.events.filter(event => 
          formatDate(new Date(event.start), 'yyyy-MM-dd', 'en-US') === date
        )
      : [];
  
    // Sort by All-Day first, then by Start Time
    return eventList.sort((a, b) => {
      // Sort All-Day events first
      if (a.eventType === CalendarEventType.ALL_DAY && b.eventType !== CalendarEventType.ALL_DAY) {
        return -1; 
      }
      if (b.eventType === CalendarEventType.ALL_DAY && a.eventType !== CalendarEventType.ALL_DAY) {
        return 1; 
      }
  
      // If both events are the same type, sort by time
      return new Date(a.start).getTime() - new Date(b.start).getTime();
    });
  }  

  expandDayBoxToggle(day: any): void {
    const key = this.getDayKey(day);
    this.isExpanded[key] = !this.isExpanded[key];
  }

  getButtonLabel(day: any): string {
    const key = this.getDayKey(day);
    const events = this.getEventsForDay(day);
    if (events.length <= 3) return '';
    return this.isExpanded[key] ? 'Collapse' : `${events.length - 3} more items`;
  }

  getDayKey(day: any): string {
    return `${day.year}-${day.month}-${day.day}`;
  }

  showEventDetails(event: ICalendarEvent): void {
    this.dialog.open(CalendarEventDetailDialogComponent, {
      data: event
    });
  }

  showTooltip(event: any, mouseEvent: MouseEvent): void {
    this.tooltipVisible = true;
    this.hoveredEvent = event;
    this.tooltipPosition.x = mouseEvent.pageX + 5;
    this.tooltipPosition.y = mouseEvent.pageY + 10;
  }

  hideTooltip() {
    this.tooltipVisible = false;
    this.hoveredEvent = null;
    this.cdr.detectChanges(); // Ensure changes are detected
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions to prevent memory leaks
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  @HostListener('window:resize')
  onResize(): void {
    this.checkForScroll();
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit called');
    this.checkForScroll(); // Initial check

    // Observe DOM changes
    const observer = new MutationObserver(() => {
      this.checkForScroll();
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }

  checkForScroll(): void {
    const dayRowContainers = document.querySelectorAll('.day-row-container') as NodeListOf<HTMLElement>;
    // console.log('Day row containers:', dayRowContainers);

    dayRowContainers.forEach(container => {
      const dayRows = container.querySelectorAll('.day-row') as NodeListOf<HTMLElement>;
      // console.log('Day rows in container:', dayRows);

      dayRows.forEach(dayRow => {
        const hasVerticalScroll = container.scrollHeight > container.clientHeight;
        // console.log('Scroll Height:', container.scrollHeight);
        // console.log('Client Height:', container.clientHeight);
        // console.log('Has vertical scroll:', hasVerticalScroll);

        if (hasVerticalScroll) {
          dayRow.classList.add('has-scroll');
          // console.log('Class added: has-scroll');
        } else {
          dayRow.classList.remove('has-scroll');
          // console.log('Class removed: has-scroll');
        }
      });
    });
  }
  
  getCalendarContainerWidth() {
    const calendarContainerElement = document.querySelector('.calendar-container') as HTMLElement;
  
    if (calendarContainerElement) {
      const containerWidth = calendarContainerElement.offsetWidth; // Now offsetWidth will work
      //console.log('containerWidth', containerWidth);
      document.documentElement.style.setProperty('--calendar-container-width', `${containerWidth}px`);
    }
  }

  fetchCalendarUsers() {
    this.subscriptions.push(
      this.calendarService.getCalendarUsers()
        .pipe(
          catchError(() => {
            this.isLoading = false; // Set loading to false if there's an error
            return of([]); // Return an empty array in case of an error
          })
        )
        .subscribe(data => {
          //console.log('users:', data)
        })
    );
  }
  
}
